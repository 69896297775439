import SwiperCore, { Pagination, Autoplay, Navigation, EffectFade } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay, Navigation, EffectFade]);
export var initSlider = function initSlider(id, parent) {
  var productsPageSlider = new SwiperCore(id, {
    speed: 800,
    // slidesPerView: 1,
    // //
    //  centeredSlides: true,
    centeredSlides: true,
    slidesPerView: "auto",
    breakpoints: {
      480: {
        // slidesPerView: 1,
        centeredSlides: true,
        slidesPerView: "auto"
      },
      768: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      }
    },
    loop: true,
    // pagination: {
    //   el: '.slider-pagination',
    //   clickable: true,
    // },
    navigation: {
      nextEl: parent + ' .slider-button-next',
      prevEl: parent + ' .slider-button-prev'
    }
  });
};
export var initMainSlider = function initMainSlider() {
  var frontPageSlider = new SwiperCore('#frontpage-slider', {
    speed: 3000,
    effect: 'fade',
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    loop: false,
    pagination: {
      el: '.slider-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.slider .slider-button-next',
      prevEl: '.slider .slider-button-prev'
    }
  });
};
export var initTestimonialSlider = function initTestimonialSlider() {
  var testimonialsPageSlider = new SwiperCore('.testimonials-slider', {
    speed: 200,
    slidesPerView: "auto",
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    },
    breakpoints: {
      480: {
        spaceBetween: 30,
        speed: 1000
      },
      768: {
        spaceBetween: 30,
        speed: 1000
      }
    },
    loop: true // pagination: {
    //   el: '.slider-pagination',
    //   clickable: true,
    // },
    // navigation: {
    //   nextEl: '.slider-button-next',
    //   prevEl: '.slider-button-prev',
    // },

  });
};