import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
export var initFixedImage = function initFixedImage() {
  var mediaQuery = window.matchMedia('(min-width: 768px)');

  (function calcMinEntryImageWrapperHeight() {
    var package_image = document.querySelector('.package img');
    var entry_image = document.querySelector('.entry-image');
    var movingImage = document.querySelector('.moving-image img');
    package_image.addEventListener('load', function () {
      var packageSize = package_image.getBoundingClientRect();
      var movingImageSize = movingImage.getBoundingClientRect();
      var height = movingImageSize.height < packageSize.height ? packageSize.height : movingImageSize.height;
      entry_image.style.minHeight = height + 130 + 'px';
      var entryPackage = document.querySelector('.entry-image .package');

      if (packageSize.height < 320) {
        entryPackage.style.top = 20 + '%';
      } else {
        entryPackage.style.top = 80 + 'px';
      }
    });
  })();

  if (mediaQuery.matches) {
    //
    var loaded = function loaded() {
      ScrollTrigger.create({
        trigger: '.moving-image',
        pin: true,
        start: "0px 20%",
        snap: 1 / 10,
        endTrigger: '#product-testimonial',
        // end: `bottom bottom+=${movingImage.offsetHeight}px`,
        end: "bottom-=".concat(movingImage.offsetHeight + endPointHeight, "px 20%"),
        pinSpacing: false,
        markers: false
      });
    };

    gsap.registerPlugin(ScrollTrigger);
    var movingImage = document.querySelector('.moving-image img');

    if (!movingImage) {
      return;
    }

    var testimonialContainer = document.querySelector('#product-testimonial');
    var endPointHeight = testimonialContainer.offsetHeight * 0.48;
    var container = document.querySelector('#content');

    if (movingImage.complete) {
      loaded();
    } else {
      movingImage.addEventListener('load', loaded);
      movingImage.addEventListener('error', function () {
        alert('error');
      });
    }
  }
}; // 370 // wysokość 47,8 %