import { initMenu, initStickyMenu } from "./modules/menu";
import { initSlider, initMainSlider, initTestimonialSlider } from "./modules/slider";
import { initTabs } from "./modules/tabs";
import { initFixedImage } from "./modules/fixed-element-scroll";
import { initVideo } from "./modules/video";
import { initPhotoSwipe } from "./modules/photoswipe";
import { initAos } from "./modules/aos";
import { initCountAnimation, parallaxAnimation } from "./modules/countAnimation";
import { initFirstLetterBig } from "./modules/firstLetterBig";
import { initForm } from "./modules/form";
import { initSearch } from "./modules/search";
document.addEventListener("DOMContentLoaded", function () {
  var page = document.querySelector("body");
  initAos();
  initMenu();
  initStickyMenu();
  initVideo();
  initSearch();

  if (page.classList.contains("home")) {
    initMainSlider();
    initSlider(".products-slider", ".new-products");
    initTestimonialSlider();
  }

  if (page.classList.contains("single-product")) {
    initFixedImage();
    initSlider(".products-slider", ".related-products");
  }

  if (page.classList.contains("page-template-about")) {
    parallaxAnimation();
  }

  if (page.classList.contains("single-post")) {
    initFirstLetterBig();
  }

  initCountAnimation();
  initTabs();
  initForm(); // initSelect();
  // initFilters();

  initPhotoSwipe(); // initAccordion();
});