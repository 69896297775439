import AOS from 'aos';
export var initAos = function initAos() {
  AOS.init({
    duration: 800,
    // values from 0 to 3000, with step 50ms
    easing: 'ease-in',
    // default easing for AOS animations
    once: true // whether animation should happen only once - while scrolling down

  });
};