export var initSearch = function initSearch() {
  var _document$querySelect, _document$querySelect2, _document$querySelect3, _document$querySelect4;

  (_document$querySelect = document.querySelector('.search-page .search-input__button')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.addEventListener('click', function () {
    var searchInput = document.querySelector('.search-page .search-input__input');
    var searchString = searchInput.value != '' ? searchInput.value : searchInput.getAttribute('placeholder');
    window.location.href = "/?s=".concat(searchString);
  });
  (_document$querySelect2 = document.querySelector('.search-page .search-input__input')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.addEventListener('keyup', function (e) {
    if (e.key !== 'Enter') {
      return;
    }

    var searchInput = document.querySelector('.search-page .search-input__input');
    var searchString = searchInput.value != '' ? searchInput.value : searchInput.getAttribute('placeholder');
    window.location.href = "/?s=".concat(searchString);
  });
  (_document$querySelect3 = document.querySelector('.nav__search .search__input')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.addEventListener('keyup', function (e) {
    if (e.key !== 'Enter') {
      return;
    }

    var searchInput = document.querySelector('.nav__search .search__input');
    var searchString = searchInput.value;
    window.location.href = "/?s=".concat(searchString);
  });
  (_document$querySelect4 = document.querySelector('.nav__search .search__icon')) === null || _document$querySelect4 === void 0 ? void 0 : _document$querySelect4.addEventListener('click', function () {
    var searchInput = document.querySelector('.nav__search .search__input');
    var searchString = searchInput.value;
    window.location.href = "/?s=".concat(searchString);
  });
};