import { gsap, Power3 } from 'gsap';
var mediaQueryMin = window.matchMedia('(min-width: 992px)');
export var initJsHoverSubmenuChanging = function initJsHoverSubmenuChanging() {
  var allSubmenus = document.querySelectorAll('.nav__submenu');

  function hideOpenedMenus() {
    allSubmenus.forEach(function (submenu) {
      submenu.classList.remove('open');
      submenu.previousElementSibling.classList.remove('open');
    });
  }

  function toggleSubmenus(current) {
    current.previousElementSibling.classList.toggle('open');
    current.classList.toggle('open');
  }

  var submenu_buttons = document.querySelectorAll('.menu-item-has-children');
  submenu_buttons.forEach(function (button) {
    var current_submenu = button.querySelector('.nav__submenu');

    if (mediaQueryMin.matches) {
      button.addEventListener('mouseenter', function (e) {
        if (current_submenu != null && !current_submenu.classList.contains('open')) {
          hideOpenedMenus();
          if (current_submenu) current_submenu.classList.add('open');
        }
      });
      var containActiveElemntForButton = button.querySelector('.nav__submenu_item--current');

      if (containActiveElemntForButton) {
        current_submenu.classList.add('open');
      }

      button.addEventListener('mouseleave', function (e) {
        var currentSubitem = document.querySelector('.nav__wrapper .nav__submenu_item--current');
        current_submenu.classList.remove('open');

        if (currentSubitem) {
          currentSubitem.closest('.nav__submenu').classList.add('open');
        }
      });
    } else {
      button.addEventListener('click', function (e) {
        var activeSubmenuAccordion = document.querySelector('.nav__submenu.open');

        if (current_submenu != null && !current_submenu.classList.contains('open')) {
          hideOpenedMenus();

          if (activeSubmenuAccordion) {
            setTimeout(function () {
              if (current_submenu) {
                toggleSubmenus(current_submenu);
              }
            }, 1000);
          } else {
            if (current_submenu) {
              toggleSubmenus(current_submenu);
            }
          }
        } else {
          if (current_submenu) {
            toggleSubmenus(current_submenu);
          }
        }
      });
    }
  });
};
export var initStickyMenu = function initStickyMenu() {
  var header = document.querySelector('.nav');

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
          args = arguments;

      var later = function later() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function toggleFixed() {
    if (window.scrollY > 0) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }

  window.addEventListener('scroll', toggleFixed);
};
export var initMenu = function initMenu() {
  var mediaQuery = window.matchMedia('(max-width: 992px)');
  /* Mobile menu initialization, can be done using pure js && pure css */

  initJsHoverSubmenuChanging();

  if (mediaQuery.matches) {
    var hamburgerEl = document.querySelector('.hamburger');
    var overlay = document.querySelector('.nav__wrapper');
    var menuItems = document.querySelectorAll('.nav__item');
    menuItems = Array.from(menuItems);
    var tmpLast = menuItems.pop();
    menuItems.unshift(tmpLast);
    var t1 = gsap.timeline({
      paused: true
    });
    t1.to(overlay, {
      duration: 0.6,
      'clip-path': 'circle(110% at 0% 50%)',
      ease: Power3.in,
      onComplete: function onComplete() {}
    });
    t1.from(menuItems, {
      duration: 0.2,
      x: -200,
      y: -50,
      opacity: 0,
      ease: Power3.in,
      stagger: 0.1
    }, '-=0.6');
    t1.reverse();

    var toggleMenu = function toggleMenu() {
      hamburgerEl.classList.toggle('is-active');

      if (hamburgerEl.classList.contains('is-active')) {
        t1.play();
      } else {
        t1.reverse(1);
      }
    };

    hamburgerEl.addEventListener('click', function () {
      toggleMenu();
    });
  }
};