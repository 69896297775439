import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
export var initPhotoSwipe = function initPhotoSwipe() {
  var pswp = document.querySelector('.pswp');

  if (pswp) {
    console.log('init');
    var options = {
      closeEl: true,
      captionEl: true,
      fullscreenEl: true,
      zoomEl: true,
      shareEl: true,
      counterEl: true,
      arrowEl: true,
      preloaderEl: true,
      index: 0,
      bgOpacity: 0.85,
      showHideOpacity: true
    };
    var selector = '.gallery__grid img';
    var galleryItems = document.querySelectorAll(selector);

    var container = _toConsumableArray(galleryItems).map(function (image) {
      return {
        src: image.dataset.url,
        w: image.dataset.width,
        h: image.dataset.height
      };
    });

    galleryItems.forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        console.log('clicked');
        options.index = parseInt(item.dataset.index) - 1;
        var gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, container, options);
        gallery.init();
      });
    });
  }
};