export var initVideo = function initVideo() {
  var allVideoButtons = document.querySelectorAll('.video__play-btn');

  if (allVideoButtons.length) {
    allVideoButtons.forEach(function (item) {
      item.addEventListener('click', function () {
        var videoContainer = item.nextElementSibling;
        var url = videoContainer.getAttribute('data-link');
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        videoContainer.setAttribute('src', "https://www.youtube.com/embed/".concat(match[2], "?autoplay=1"));
        videoContainer.addEventListener('load', function () {
          item.closest('.video__wrapper').classList.add('is-disable');
        });
      });
    });
  }
};