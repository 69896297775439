import { gsap, Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
export var parallaxAnimation = function parallaxAnimation() {
  var mediaQuery = window.matchMedia('(min-width: 768px)');
  gsap.registerPlugin(ScrollTrigger);
  var tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.about__header',
      scrub: true,
      pin: false,
      start: 'top top',
      end: '+=200%',
      endTrigger: '.why-we',
      markers: false
    }
  });

  if (mediaQuery.matches) {
    tl.to('.about__header_decor', {
      yPercent: 50,
      duration: 1
    });
    tl.to('.about-company__image', {
      yPercent: 40,
      duration: 1
    }, '-=0.5');
    tl.from('.why-we__decor', {
      yPercent: -100,
      duration: 1
    }, '-=0.5');
  } else {
    tl.to('.about__header_decor', {
      yPercent: 50,
      duration: 1
    });
  }

  var history = gsap.timeline({
    scrollTrigger: {
      trigger: '.history',
      scrub: true,
      pin: false,
      start: 'top top',
      end: 'bottom bottom',
      markers: false
    }
  });
  history.staggerFrom('.history__item ', 1, {
    y: '100%',
    stagger: 0.6
  });
};
export var initCountAnimation = function initCountAnimation() {
  var item = document.querySelector('.intensive__value');
  var progress = document.querySelector('.progress');
  var about_items = document.querySelectorAll('.numbers .numbers__value > span'); //intensive__value

  if (progress) {
    gsap.from(progress, {
      scrollTrigger: '.progress',
      width: 0,
      // paused: true,
      delay: 0.7,
      duration: 2,
      ease: Power1.easeIn,
      onUpdate: textContent.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    });
  } //intensive__value


  if (item) {
    gsap.from(item, {
      scrollTrigger: '.progress',
      textContent: 0,
      // paused: true,
      duration: 1,
      ease: Power1.ease,
      snap: {
        textContent: 1
      },
      stagger: 1,
      onUpdate: textContent.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    });
  }

  if (about_items.length != 0) {
    gsap.from(about_items, {
      scrollTrigger: {
        trigger: ".numbers",
        start: "50% bottom"
      },
      textContent: 0,
      duration: 3,
      ease: Power1.ease,
      snap: {
        textContent: 1
      }
    });
  }
};